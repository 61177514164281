<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="12" sm="12">
                        <div class="table-responsive">
                          <b-table-simple striped bordered>
                            <b-thead>
                                <b-tr class="text-center align-top">
                                    <b-th rowspan="2">
                                        {{ $t('globalTrans.sl_no') }}
                                    </b-th>
                                    <b-th colspan="6">
                                        {{ $t('irriWaterTest.location_of_sample') }}
                                    </b-th>
                                    <b-th rowspan="2">
                                        {{ $t('irriWaterTest.date_test') }}
                                    </b-th>
                                    <b-th colspan="12">
                                        {{ $t('irriWaterTest.water_quality_test_result') }}
                                    </b-th>
                                </b-tr>
                                <b-tr class="text-center align-top">
                                    <b-th>
                                        {{ $t('globalTrans.district') }}
                                    </b-th>
                                    <b-th>
                                        {{ $t('globalTrans.upazila') }}
                                    </b-th>
                                    <b-th>
                                        {{ $t('globalTrans.union') }}
                                    </b-th>
                                    <b-th>
                                        {{ $t('irriPumpInformation.mouza_no') }}
                                    </b-th>
                                    <b-th>
                                        {{ $t('irriPumpInformation.jl_no') }}
                                    </b-th>
                                    <b-th>
                                        {{ $t('irriPumpInformation.plot_no') }}
                                    </b-th>
                                    <b-th>
                                        {{ $t('irriWaterTest.as_ppm') }}
                                    </b-th>
                                    <b-th>
                                        {{ $t('irriWaterTest.ca_ppm') }}
                                    </b-th>
                                    <b-th>
                                        {{ $t('irriWaterTest.mg_ppm') }}
                                    </b-th>
                                    <b-th>
                                        {{ $t('irriWaterTest.fe_ppm') }}
                                    </b-th>
                                    <b-th>
                                        {{ $t('irriWaterTest.ci_ppm') }}
                                    </b-th>
                                    <b-th>
                                        {{ $t('irriWaterTest.total_hardness_ppm') }}
                                    </b-th>
                                    <b-th>
                                        {{ $t('irriWaterTest.ph') }}
                                    </b-th>
                                    <b-th>
                                        {{ $t('irriWaterTest.ec_psm') }}
                                    </b-th>
                                    <b-th>
                                        {{ $t('irriWaterTest.tds') }}
                                    </b-th>
                                    <b-th>
                                        {{ $t('irriWaterTest.turbidity') }}
                                    </b-th>
                                    <b-th>
                                        {{ $t('irriWaterTest.do') }}
                                    </b-th>
                                    <b-th>
                                        {{ $t('irriWaterTest.fc') }}
                                    </b-th>
                                </b-tr>
                                <b-tr class="text-center align-top">
                                    <b-th>
                                        {{ $n(1) }}
                                    </b-th>
                                    <b-th>
                                       {{ $n(2) }}
                                    </b-th>
                                    <b-th>
                                        {{ $n(3) }}
                                    </b-th>
                                    <b-th>
                                        {{ $n(4) }}
                                    </b-th>
                                    <b-th>
                                        {{ $n(5) }}
                                    </b-th>
                                    <b-th>
                                        {{ $n(6) }}
                                    </b-th>
                                    <b-th>
                                        {{ $n(7) }}
                                    </b-th>
                                    <b-th>
                                        {{ $n(8) }}
                                    </b-th>
                                    <b-th>
                                        {{ $n(9) }}
                                    </b-th>
                                    <b-th>
                                        {{ $n(10) }}
                                    </b-th>
                                    <b-th>
                                        {{ $n(11) }}
                                    </b-th>
                                    <b-th>
                                        {{ $n(12) }}
                                    </b-th>
                                    <b-th>
                                        {{ $n(13) }}
                                    </b-th>
                                    <b-th>
                                        {{ $n(14) }}
                                    </b-th>
                                    <b-th>
                                        {{ $n(15) }}
                                    </b-th>
                                    <b-th>
                                        {{ $n(16) }}
                                    </b-th>
                                    <b-th>
                                        {{ $n(17) }}
                                    </b-th>
                                    <b-th>
                                        {{ $n(18) }}
                                    </b-th>
                                    <b-th>
                                        {{ $n(19) }}
                                    </b-th>
                                    <b-th>
                                        {{ $n(20) }}
                                    </b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr>
                                    <b-td>
                                        {{ $n(1) }}
                                    </b-td>
                                    <b-td>
                                        {{ locale === 'bn' ? this.waterTestingRequest.district_name_bn : this.waterTestingRequest.district_name }}
                                    </b-td>
                                    <b-td>
                                        {{ locale === 'bn' ? this.waterTestingRequest.upazilla_name_bn : this.waterTestingRequest.upazilla_name }}
                                    </b-td>
                                    <b-td>
                                        {{ locale === 'bn' ? this.waterTestingRequest.union_name_bn : this.waterTestingRequest.union_name }}
                                    </b-td>
                                    <b-td>
                                        {{ locale === 'bn' ? this.waterTestingRequest.mouza_name_bn : this.waterTestingRequest.mouza_name }}
                                    </b-td>
                                    <b-td>
                                        {{ $n(this.waterTestingRequest.jl_no) }}
                                    </b-td>
                                    <b-td>
                                        {{ $n(this.waterTestingRequest.plot_no) }}
                                    </b-td>
                                    <b-td>
                                        {{ this.waterTestingRequest.test_date ? (this.waterTestingRequest.test_date | dateFormat) : '-' }}
                                    </b-td>
                                    <b-td>
                                        {{ $n(this.waterTestingRequest.as_ppm) }}
                                    </b-td>
                                    <b-td>
                                        {{ $n(this.waterTestingRequest.ca_ppm) }}
                                    </b-td>
                                    <b-td>
                                        {{ $n(this.waterTestingRequest.mg_ppm) }}
                                    </b-td>
                                    <b-td>
                                        {{ $n(this.waterTestingRequest.fe_ppm) }}
                                    </b-td>
                                    <b-td>
                                        {{ $n(this.waterTestingRequest.ci_ppm) }}
                                    </b-td>
                                    <b-td>
                                        {{ $n(this.waterTestingRequest.total_hardness_ppm) }}
                                    </b-td>
                                    <b-td>
                                        {{ $n(this.waterTestingRequest.ph) }}
                                    </b-td>
                                    <b-td>
                                        {{ $n(this.waterTestingRequest.ec_psm) }}
                                    </b-td>
                                    <b-td>
                                        {{ $n(this.waterTestingRequest.tds) }}
                                    </b-td>
                                    <b-td>
                                        {{ $n(this.waterTestingRequest.turbidity) }}
                                    </b-td>
                                    <b-td>
                                        {{ $n(this.waterTestingRequest.do) }}
                                    </b-td>
                                    <b-td>
                                        {{ $n(this.waterTestingRequest.fc) }}
                                    </b-td>
                                </b-tr>
                            </b-tbody>
                          </b-table-simple>
                        </div>
                      </b-col>
                      <b-col lg="12" sm="12">
                        <a :href="irriSchemeServiceBaseUrl + 'download-attachment?file=uploads/water-test-reports/original/' + this.waterTestingRequest.attachment" class="btn btn-success"><i class="fa fa-cloud" aria-hidden="true"></i> {{ $t('globalTrans.attachment') }}</a>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import { irriSchemeServiceBaseUrl } from '@/config/api_config'
export default {
  name: 'FormLayout',
  props: ['id', 'item'],
  created () {
    this.waterTestingRequest = this.item
  },
  data () {
    return {
        irriSchemeServiceBaseUrl: irriSchemeServiceBaseUrl,
        waterTestingRequest: {},
        payments: [],
        slOffset: 1
    }
  },
  computed: {
    locale: function () {
        return this.$i18n.locale
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
</style>
