export const testListApi = '/list'
export const testStoreApi = '/store'
export const testUpdateApi = '/update'
export const testToggleStatusApi = '/toggle-status'

// farmer-water-test-request
export const waterTestingRequestList = 'farmer-water-test-application/list'
export const waterTestingRequestStore = 'farmer-water-test-application/store'
export const waterTestingRequestUpdate = 'farmer-water-test-application/update'
export const waterTestingRequestToggleStatus = 'farmer-water-test-application/toggle-status'
export const waterTestingRequestDestroy = 'farmer-water-test-application/delete'
export const waterTestingRequestProcessing = 'farmer-water-test-application/processing'
export const waterTestingRequestSampleCollected = 'farmer-water-test-application/sample-collected'

// Water Testing Type wise Request
export const irrgationWaterTestingList = 'farmer-water-test-application/irrigation-water-testing-list'
export const irrgationWaterTestingListAll = 'farmer-water-test-application/irrigation-water-testing-list-all'

export const drinkingWaterTestingList = 'farmer-water-test-application/drinking-water-testing-list'
export const drinkingWaterTestingListAll = 'farmer-water-test-application/drinking-water-testing-list-all'

export const industrialWaterTestingList = 'farmer-water-test-application/industrial-water-testing-list'
export const industrialWaterTestingListAll = 'farmer-water-test-afarpplication/irrigation-water-testing-list-all'

// Nothi api
export const watertestingnothi = 'watertestingnothi/'
export const pendingNothiIrrgationWaterTestingList = watertestingnothi + 'irrigation-water-testing-sent-nothi'
export const reSentNothiIrrgationWaterTestingList = watertestingnothi + 'irrigation-water-testing-re-sent-nothi'

export const pendingNothiDrinkingWaterTestingList = watertestingnothi + 'drinking-water-testing-sent-nothi'
export const reSentNothiDrinkingWaterTestingList = watertestingnothi + 'drinking-water-testing-re-sent-nothi'

export const pendingNothiIndustrialWaterTestingList = watertestingnothi + 'industrial-water-testing-sent-nothi'
export const reSentNothiIndustrialWaterTestingList = watertestingnothi + 'industrial-water-testing-re-sent-nothi'

export const irrgationWaterTestingSampleSendToLab = 'farmer-water-test-application/sample-send-to-lab/'
export const waterTestingRequestReject = 'farmer-water-test-application/reject/'
export const waterTestingStoreByAdmin = 'farmer-water-test-application/store-by-admin'
export const waterTestingManualEntryList = 'farmer-water-test-application/manual-entry-list'

// Water Testing Sample List
export const waterTestSampleList = 'farmer-water-test-application/water-sample-list'
export const waterTestSampleListAll = 'farmer-water-test-application/water-sample-list-all'

export const waterTestingRequestReportCollect = 'farmer-water-test-application/reports-collect'
export const waterTestingRequestReportCollectList = 'farmer-water-test-application/reports-collect-list'
export const waterTestingRequestReported = 'farmer-water-test-application/reported/'

// Water Testing Report
export const waterTestingRequestReportedList = 'farmer-water-test-application/reported-list'
export const publichWaterTestingRequestReport = 'farmer-water-test-application/publish-report'
export const waterTestingPaymentReport = 'testing-payment/report'

// Dashboard
export const waterTestingDashboard = 'farmer-water-test-application/dashboard'
export const getLoggedUserSupervisor = '/user/supervisor'
