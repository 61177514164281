import i18n from '@/i18n'
import Store from '@/store'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (reportTitle, thisObject, data) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })
    if (i18n.locale === 'bn') {
      pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    } else {
      pdfMake.vfs = pdfFontsEn.pdfMake.vfs
    }
    const pdfContent = [
      { text: reportTitle, style: 'header2', alignment: 'center' }
    ]

    const allRowsHead = [
        [
        { text: thisObject.$t('complain.organization'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.org_name : data.org_name_bn, alignment: 'left', style: 'search' },
        { text: thisObject.$t('farmerOperator.applicant_name'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.name : data.name_bn, alignment: 'left', style: 'search' }
      ]
    ]

    allRowsHead.push([
      { text: thisObject.$t('complain.division'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.division_name : data.division_name_bn, alignment: 'left', style: 'search' },
      { text: thisObject.$t('pump_install.district'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.district_name : data.district_name_bn, alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('pump_install.upazila'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.upazilla_name : data.upazilla_name_bn, alignment: 'left', style: 'search' },
      { text: thisObject.$t('pump_install.union'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.union_name : data.union_name_bn, alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('pump_install.village'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.far_village : data.far_village_bn, alignment: 'left', style: 'search' },
      { text: thisObject.$t('globalTrans.from_date'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: data.from_date !== null ? thisObject.$d(new Date(data.from_date)) : '', alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('farmerOperator.sample_number'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: thisObject.$n(data.sample_number, { useGrouping: false }), alignment: 'left', style: 'search' },
      { text: thisObject.$t('globalTrans.to_date'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: data.to_date !== null ? thisObject.$d(new Date(data.to_date)) : '', alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('globalTrans.status'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.testing_status_name : data.testing_status_name_bn, alignment: 'left', style: 'search' },
      { text: thisObject.$t('farmerOperator.farmer_id'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: thisObject.$n(data.farmer_id, { useGrouping: false }), alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('externalUserIrrigation.application_id'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.application_id : data.application_id, alignment: 'left', style: 'search' },
      { text: thisObject.$t('irriPumpInformation.mouza_no'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.mouza_name : data.mouza_name_bn, alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('irriPumpInformation.jl_no'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: thisObject.$n(data.jl_no), alignment: 'left', style: 'search' },
      { text: thisObject.$t('irriPumpInformation.plot_no'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: thisObject.$n(data.plot_no), alignment: 'left', style: 'search' }
    ])

      pdfContent.push({
        table: {
          headerRows: 0,
          widths: ['24%', '2%', '24%', '24%', '2%', '24%'],
          body: allRowsHead
        },
        layout: {
          hLineWidth: function (i, node) {
            return 0
          },
          vLineWidth: function (i, node) {
            return 0
          }
        }
      })

      if (data.water_testing_parameter_id !== null) {
        const payments = JSON.parse(data.water_testing_parameter_id)
        let totalAmount = 0
        payments.map(item => {
          totalAmount += item.amount
        })
        // prameter Payment List
        pdfContent.push({ text: thisObject.$t('irriWaterTest.prameter_payment_list'), style: 'header3', alignment: 'center', decoration: 'underline' })
        const headList = [
          { text: thisObject.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
          { text: thisObject.$t('irriWaterTest.prameter_name'), style: 'th', alignment: 'center' },
          { text: thisObject.$t('globalTrans.amount'), style: 'th', alignment: 'center' }
        ]

        const allRowsPayment = [headList]
        payments.map((item, index) => {
          const newRow = [
            { text: thisObject.$n(index + 1), style: 'td', alignment: 'center' },
            { text: i18n.locale === 'en' ? item.text : item.text, style: 'td', alignment: 'center' },
            { text: thisObject.$n(item.amount), style: 'td', alignment: 'center' }
          ]
          allRowsPayment.push(newRow)
        })

        const paymentFootList = [
          { text: thisObject.$t('irriWaterTest.total_amount'), style: 'th', colSpan: 2, alignment: 'center', bold: true },
          {},
          { text: thisObject.$n(totalAmount), style: 'th', alignment: 'center', bold: true }
        ]

        allRowsPayment.push(paymentFootList)

        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['12%', '*', '*'],
            body: allRowsPayment
          }
        })
      }
      const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3],
            bold: true
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [0, 10, 0, 7]
          },
          fertilizerSHeader: {
              fontSize: 10,
              margin: [40, 0, 0, 0]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: i18n.locale === 'bn' ? 14 : 12,
            margin: [0, 10, 0, 10]
          },
          header3: {
            fontSize: i18n.locale === 'bn' ? 13 : 11,
            margin: [0, 10, 0, 5]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
